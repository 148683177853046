.errorMsg {
  color: rgb(255, 255, 255);
  background: #797979;
  padding: 1rem;
}

.upload-section {
  display: flex;
  height: 100px;
  margin-bottom: 10px;

  & > div {
    width: 50%;
  }
}

.drop-zone {
  margin-bottom: 10px;
  padding: 40px 10px;
  height: inherit;
  border: 2px dashed #e9ebeb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.files-container {
  width: 98%;
  overflow-x: auto;
}

.files-table {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;

  tr,
  td,
  th {
    border: 1px solid #ccc;
    padding: 10px;
  }

  .file-title {
    width: 20%;
  }

  .file-description {
    width: 70%;
  }
}

@media screen and (max-width: 1000px) {
  .upload-section {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }
  .upload-section {
    height: unset;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 25px;
  }
  .drop-zone {
    width: 100%;
  }
}

$block: ".quantity-input";
$border: 0 solid #dbdbdb;
$border-radius: 6px;
$color--primary: #eaf2ea;
$modifier--text-color: #888;

#{$block} {
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.16), 0 1.5px 3px rgba(0, 0, 0, 0.23);
  border-radius: $border-radius;

  &:focus {
    background: red;
  }
}

#{$block}__modifier,
#{$block}__screen {
  user-select: none;
  outline: none;
}

#{$block}__modifier {
  padding: 0.7rem;
  width: 3rem;
  height: 3rem;
  font-size: 1rem;

  background: $color--primary;
  color: $modifier--text-color;
  border: $border;
  text-align: center;
  text-shadow: 0 1px 0 rgba(#fff, 0.6);

  cursor: pointer;

  &:hover {
    background: darken($color--primary, 10%);
    color: darken($modifier--text-color, 20%);
  }

  &--left {
    border-radius: $border-radius 0 0 $border-radius;
  }

  &--right {
    border-radius: 0 $border-radius $border-radius 0;
  }
}

#{$block}__screen {
  width: 2.6rem;
  padding: 0.8rem;
  font-size: 1rem;

  border: 0;
  border-top: $border;
  border-bottom: $border;
  text-align: center;
}
