@media (max-width: 1000px) {
  .FrontPage-toni {
    max-width: 500px !important;
    margin-left: 0px;
    img {
      border-radius: 12px !important;
      margin-top: 20px;
      margin-left: 0px !important;
      margin-bottom: 25px;
    }
  }
  .headercontainer {
    justify-content: right;
    text-indent: 2px;
    background: #f0efd2;
    width: auto;
    padding-left: 3em;
    padding-top: 25px;
  }
}

@media only screen and (min-width: 1px) and (max-width: 1000px) {
  .video-container {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 30px;
    width: 100%;
  }
}

@media (min-width: 1000px) {
  .headercontainer {
    display: flex;
    justify-content: center;
    text-indent: 3px;
    padding: 1em;
    background: #f0efd2;
    padding-left: 20em;
    padding-top: 25px;
    padding-right: 20em;
  }

  .image-and-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .video-container {
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 60px;
    margin-top: 15px;
  }
  .video-row {
    display: flex;
  }
  .video-item {
    width: 100%;
  }
}

@media only screen and (min-width: 1px) and (max-width: 1000px) {
  .video-frame {
    width: 320px;
    height: 200px;
  }
  .logo-enjoy-wrapper-desktop {
    display: none;
  }
}

@media (min-width: 1000px) {
  .video-frame {
    width: 560px;
    height: 315px;
  }
  .logo-enjoy-wrapper-mobile {
    display: none;
  }
  .logo-enjoy-wrapper-desktop {
    display: flex;
    flex-direction: column;
    width: 560px;
    height: 315px;
    margin-top: 20px;
    margin-bottom: -20px;
  }
}

@media only screen and (min-width: 1px) and (max-width: 1000px) {
  .article-page {
    padding: 1em;
    margin-top: 2em;
    margin-bottom: 2em;
    margin-left: 2em;
    margin-right: 2em;
    font-size: 0.8rem;
  }
}

@media (min-width: 1000px) {
  .article-page {
    padding: 1em;
    margin-top: 2em;
    margin-bottom: 2em;
    margin-left: 17em;
    margin-right: 17em;
    font-size: 1.1rem;
  }
}

.some-icon {
  height: 20px;
  width: 20px;
}

.color-nav {
  color: #000000 !important;
  padding: 5;
}

.footer-line {
  border: 0.25px;
  min-height: 0.25em;
  line-height: 0.25;
}

@media only screen and (min-width: 1px) and (max-width: 1000px) {
  .FrontPage-logo {
    width: 0%;
  }

  .FrontPage-text {
    z-index: 2;
    font-size: 16px;
    display: block;
    color: #000000;
    text-align: left;
    margin-bottom: 10px;
  }
}

.navbar {
  padding: 0 !important;
}

@media (min-width: 1000px) {
  .FrontPage-logo {
    z-index: 1;
    height: 80px;
    width: 80px;
  }
  .FrontPage-text {
    z-index: 2;
    color: #000000;
    font-size: 18px;
    width: 900px;
    margin-bottom: 10px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .FrontPage-logo {
    animation: FrontPage-logo-spin infinite 10s linear;
  }
}

@keyframes FrontPage-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.FrontPage-bottom {
  display: block;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.checkbox {
  margin-left: 12px;
}

.formats {
  margin-top: 12px;
}
.onepager {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.lite-youtube-fallback {
  aspect-ratio: 16 / 9; /* matches YouTube player */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  background-color: #fff;
  color: #fff;
  text-decoration: none;
}

/* right-facing triangle "Play" icon */
.lite-youtube-fallback::before {
  display: block;
  content: "";
  border: solid transparent;
  border-width: 2em 0 2em 3em;
  border-left-color: red;
}

.lite-youtube-fallback:hover::before {
  border-left-color: #fff;
}

.lite-youtube-fallback:focus {
  outline: 2px solid red;
}
